import React from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import styled from "styled-components";
import { ReactComponent as DownloadIcon } from "../../assets/download-grey.svg";

interface Record {
  typeOfNotarizationText: string;
  notarizationDate: { _seconds: number; _nanoseconds: number };
  signerName: string;
  signerAddress: string;
  documentName: string;
  documentDate: string;
  documentType: string;
  idEvidence: string;
  fee: string;
  emailCopies?: string[];
}

interface CsvExportButtonProps {
  records: Record[];
}

const CsvExportButton: React.FC<CsvExportButtonProps> = ({ records }) => {
  const headers = [
    { label: "Type of notarization", key: "typeOfNotarizationText" },
    { label: "Notarization Date", key: "notarizationDate" },
    { label: "Signer", key: "signerName" },
    { label: "Signer Address", key: "signerAddress" },
    { label: "Document Description", key: "documentName" },
    { label: "Document Date", key: "documentDate" },
    { label: "Identification", key: "documentType" },
    { label: "Basis of Knowledge", key: "idEvidence" },
    { label: "Fee Charged", key: "fee" },
    { label: "Copy Recipient", key: "emailCopies" },
  ];

  const data = records.map((record) => ({
    ...record,
    notarizationDate: `${moment(record.notarizationDate._seconds * 1000).format(
      "MM/DD/YYYY HH:mm"
    )} Central Time`,
    emailCopies: record.emailCopies ? record.emailCopies.join(", ") : "",
  }));

  return (
    <StyledCSVLink
      data={data}
      headers={headers}
      filename="Electronic_notary_records.csv"
    >
      <DownloadIcon />
      Download CSV
    </StyledCSVLink>
  );
};

export default CsvExportButton;

const StyledCSVLink = styled(CSVLink)`
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  color: rgb(33, 33, 33);
  background-color: rgb(250, 250, 250);
  line-height: 36px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 12px;
  cursor: pointer;
  // display: inline-block;
  // margin: 16px 0px;
  width: fit-content;
  text-decoration: none;
  flex: 2;
`;
