export const SESSION_STAGES = [
    { value: 'start', label: 'start' },
    { value: 'payment', label: 'payment' },
    { value: 'paymentByPayer', label: 'paymentByPayer' },
    { value: 'paymentStatusWait', label: 'paymentStatusWait' },
    { value: 'personalDetails', label: 'personalDetails' },
    { value: 'checkPersonalDetails', label: 'checkPersonalDetails' },
    { value: 'idVerificationStart', label: 'idVerificationStart' },
    { value: 'idVerificationFront', label: 'idVerificationFront' },
    { value: 'idVerificationBack', label: 'idVerificationBack' },
    { value: 'idVerificationFailed', label: 'idVerificationFailed' },
    { value: 'KBAStart', label: 'KBAStart' },
    { value: 'KBAInProgress', label: 'KBAInProgress' },
    { value: 'KBAFails', label: 'KBAFails' },
    { value: 'KBAInSuccess', label: 'KBAInSuccess' },
    { value: 'userInRoom', label: 'userInRoom' },
    { value: 'officialInRoom', label: 'officialInRoom' },
    { value: 'userSigningProcessStart', label: 'userSigningProcessStart' },
    { value: 'userSigned', label: 'userSigned' },
    { value: 'officialSealed', label: 'officialSealed' },
    { value: 'waitingForTheDocument', label: 'waitingForTheDocument' },
    { value: 'sessionEnded', label: 'sessionEnded' },
    { value: 'userSessionEnded', label: 'userSessionEnded' },
    { value: 'cancelled', label: 'cancelled' },
    { value: 'cameraCheckStart', label: 'cameraCheckStart' },
    { value: 'mobileVerificationIdCapture', label: 'mobileVerificationIdCapture' },
    { value: 'mobileVerificationIdCaptureBack', label: 'mobileVerificationIdCaptureBack' },
    { value: 'mobileVerificationSignatureInitial', label: 'mobileVerificationSignatureInitial' },
    { value: 'mobileVerificationSignatureCreation', label: 'mobileVerificationSignatureCreation' },
    { value: 'mobileVerificationPersonalDetails', label: 'mobileVerificationPersonalDetails' }
];

export const CANCELLED_STATUS= "cancelled"
export const CREATED_STATUS= "created"
export const START_STATUS= "start"