import React from "react";
import styled from "styled-components";

interface NotarySessionScheduledPopupProps {
  onClose: () => void;
  onSessionDetails: () => void;
  onReturnToRequests: () => void;
}

const NotarySessionScheduledPopup: React.FC<NotarySessionScheduledPopupProps> = ({
  onClose,
  onSessionDetails,
  onReturnToRequests,
}) => {
  return (
    <>
      <PopupOverlay onClick={onClose}>
        <PopupContainer onClick={(e) => e.stopPropagation()}>
          <PopupHeader>Notary Session Scheduled</PopupHeader>
          <ButtonGroup>
            <Button onClick={onSessionDetails}>
              Session Details
            </Button>
            <Button onClick={onReturnToRequests}>
              Return to Requests
            </Button>
          </ButtonGroup>
        </PopupContainer>
      </PopupOverlay>
    </>
  );
};

export default NotarySessionScheduledPopup;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PopupHeader = styled.h2`
  margin-bottom: 20px;
  width: 100%;
  margin-top: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
`;

const Button = styled.button<{ variant?: string }>`
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 8px;
  cursor: pointer;
background-color: #007bff;
color: white;
`;