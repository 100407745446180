import React, { useState } from "react";
import styled from "styled-components";
import { AppDispatch, getRecordingUrl, updateRecord } from "../../store";
import { useDispatch } from "react-redux";
import { API_STATUS } from "../../utils";
import { toast } from "react-toastify";
import moment from "moment";
import { ReactComponent as PencilAltIcon } from "../../assets/pencil-alt.svg";

interface AcknowledgmentDetails {
  signerName: string;
  documentName: string;
  sessionId: string;
  documentType: string;
  fee: string;
  idExpiration: string;
  signerAddress: string;
  documentDate: string;
  idEvidence: string;
  notes: string;
  id: string;
  typeOfNotarizationText: string;
  notarizationDate: any;
}

interface AcknowledgmentBoxProps {
  acknowledge: AcknowledgmentDetails;
}

interface DateFormatProps {
  item: {
    notarizationDate: {
      _seconds: number;
    };
  };
  format: string;
}

const AcknowledgmentBox: React.FC<AcknowledgmentBoxProps> = ({
  acknowledge,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDownloadable, setIsDownloadable] = useState(false);
  const [recordingUrl, setRecordingUrl] = useState("");
  const [formData, setFormData] = useState(acknowledge);
  const dispatch = useDispatch<AppDispatch>();

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setFormData(acknowledge);
    setIsEditing(false);
  };

  const isFormValid = () => {
    return Object.keys(formData).every((key) => {
      const value = formData[key as keyof AcknowledgmentDetails];
      if (["notes", "secondSignerAddress"].includes(key)) {
        return true;
      }
      if (value === null || value === undefined) {
        return false;
      }
      if (typeof value === "object") {
        return typeof value._seconds === "number";
      }
      return Boolean(value);
    });
  };


  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const renderInputOrText = (key: keyof AcknowledgmentDetails) => {
    return isEditing ? (
      <Input
        type="text"
        name={key}
        value={formData[key]}
        onChange={handleInputChange}
      />
    ) : (
      <Text>{formData[key]}</Text>
    );
  };

  const handleSave = async () => {
    if (!isFormValid()) {
      toast.error("Please fill in all fields before saving.", {
        autoClose: 5000,
      });
      return;
    }
    setIsUpdating(true);
    try {
      const updatedResponse = await dispatch(
        updateRecord({ recordId: acknowledge.id, updateData: formData })
      );
      if (updatedResponse.meta.requestStatus == API_STATUS.fulfilled) {
        toast.success("Successfully Updated", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsEditing(false);
      }
      if (updatedResponse.meta.requestStatus == API_STATUS.rejected) {
        toast.error("Failed to update", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err: any) {
      console.error("error on updating record", err);
    } finally {
      setIsUpdating(false);
    }
  };
  const gettingRecordingUrl = async () => {
    const getRecordingUrlResponse = await dispatch(
      getRecordingUrl({
        sessionId: acknowledge?.sessionId,
      })
    );
    if (getRecordingUrlResponse.meta.requestStatus === "fulfilled") {
      const url = getRecordingUrlResponse.payload?.url
      setIsDownloadable(true);
      setRecordingUrl(url);
    }
    else {
      console.log("getRecordingUrlResponse", getRecordingUrlResponse);
      const error = getRecordingUrlResponse.payload?.error
      toast.error(error, { autoClose: 5000 });

    }
  }

  const handleDownload = () => {
    if (recordingUrl) {
      const link = document.createElement("a");
      link.href = recordingUrl;
      link.download = `Recording-${acknowledge?.sessionId}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast.error("Recording URL is not available.", {
        autoClose: 5000,
      });
    }
  };

  const handleOpenInNewPage = () => {
    if (recordingUrl) {
      window.open(recordingUrl, "_blank");
    } else {
      toast.error("Recording URL is not available.", {
        autoClose: 5000,
      });
    }
  };


  return (
    <AcknowledgmentContainer>
      <Header isEditing={isEditing}>
        <HeaderText>{acknowledge?.typeOfNotarizationText}</HeaderText>
        <HeaderText center>
          <DateFormat
            item={{ notarizationDate: acknowledge?.notarizationDate }}
            format="MM/DD/YYYY hh:mm A"
          />
          Central Time
        </HeaderText>
        {isEditing ? (
          <ButtonBox>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              primary
              onClick={handleSave}
              disabled={!isFormValid() || isUpdating}
            >
              {isUpdating ? <Loader /> : "Save"}
            </Button>
          </ButtonBox>
        ) : (
          <EditButton onClick={handleEditToggle}>
            <PencilAltIcon />
          </EditButton>
        )}
      </Header>
      <Content>
        <Column>
          <Label>Signer</Label>
          {renderInputOrText("signerName")}
          <Label>Document Description</Label>
          {renderInputOrText("documentName")}
          <Label>Identification</Label>
          {renderInputOrText("documentType")}
          <Label>Fee Charged</Label>
          {renderInputOrText("fee")}
          <Label>Expiration Date</Label>
          {renderInputOrText("idExpiration")}
        </Column>
        <Column>
          <Label>Signer Address</Label>
          {renderInputOrText("signerAddress")}
          <Label>Document Date</Label>
          {renderInputOrText("documentDate")}
          <Label>Basis of Knowledge</Label>
          {renderInputOrText("idEvidence")}
          {!isEditing && !isDownloadable ? (
            <Label onClick={gettingRecordingUrl}>
              <GetLinkText>Get Link to Saved Video Conference</GetLinkText>
            </Label>
          ) : !isEditing && isDownloadable ? (
            <DownloadButton primary onClick={handleOpenInNewPage}>
              Download
            </DownloadButton>
          ) : null}


          <Label>Notes</Label>
          {renderInputOrText("notes")}
        </Column>
      </Content>
    </AcknowledgmentContainer>
  );
};

export default AcknowledgmentBox;

export const DateFormat: React.FC<DateFormatProps> = ({ item, format }) => {
  const dateInMilliseconds = item?.notarizationDate?._seconds * 1000;
  const formattedDate = moment(dateInMilliseconds)?.format(format);

  return <span>{formattedDate} </span>;
};


const AcknowledgmentContainer = styled.div`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  margin: 16px 0;
  // width: 70%;
  // margin-left: 6%;
`;

const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #13160b; // Adjust the color to match your button or theme
  width: 12px; // Adjust size as needed
  height: 12px; // Adjust size as needed
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Header = styled.div<{ isEditing: boolean }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.secondary};
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.isEditing ? "12px 22px" : "14px 22px")};
`;

const HeaderText = styled.span<{ center?: boolean }>`
  text-align: ${(props) => (props.center ? "center" : "left")};
`;

const EditButton = styled.button`
  background: none;
  color: inherit;
  text-align: end;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const Button = styled.button<{ primary?: boolean; isDisabled?: boolean }>`
  background: ${(props) => (props.primary ? "#007bff" : "none")};
  color: ${(props) => (props.primary ? "#fff" : "inherit")};
  border: none;
  padding: 6px 12px;
  margin-left: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 3px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  // pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:disabled {
    background: #6699cc;
  }
`;

const DownloadButton = styled.button<{ primary?: boolean; isDisabled?: boolean }>`
  background: ${(props) => (props.primary ? "#007bff" : "none")};
  color: ${(props) => (props.primary ? "#fff" : "inherit")};
  border: none;
  padding: 6px 12px;
  margin-top: 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 3px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  // pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:disabled {
    background: #6699cc;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 0px 12px;
`;

const ButtonBox = styled.div`
display:flex;
justify:content:flex-end;
`;

const Column = styled.div`
  flex: 1;
  padding: 10px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 12px;
  margin-top: 18px;
  
`;

const Text = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;

const GetLinkText = styled.div`
  font-size: 12px;
  margin-top: 8px;
  color: blue;
  cursor: pointer;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #ccc;
  box-shadow: 0px -1px 5px 1px rgba(0, 0, 0, 0.1);
  outline: none;

  &:focus {
    border-bottom: 2px solid #007bff;
  }
`;

const TextLink = styled.a`
  font-size: 12px;
  color: blue;
  cursor: pointer;
`;
